/** @jsx jsx */
import { jsx } from "theme-ui";

const LogoIcon = () => {
  return (
    <div sx={{ width: "47px", height: "47px" }}>
      <svg
        sx={{ width: "100%", height: "100%" }}
        viewBox="0 0 47 47"
        aria-hidden="true"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <path id="a" d="M0 0h56v56H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path fill="#09063C" d="M-46-24H978v1024H-46z" />
          <g transform="translate(-5 -6)">
            <mask id="b" fill="#fff">
              <use xlinkHref="#a" />
            </mask>
            <g fillRule="nonzero" mask="url(#b)">
              <path
                d="M41.983 19.654c1.339 3.97 1.356 8.187.545 12.165-.81 3.979-2.446 7.712-5.107 10.39-2.662 2.677-6.354 4.307-10.186 4.842-3.83.545-7.803.001-11.052-1.839-3.249-1.84-5.768-4.984-7.663-8.567-1.897-3.593-3.155-7.635-2.602-11.417.56-3.778 2.934-7.303 5.93-9.843 3.008-2.543 6.63-4.105 10.641-5.135 4.016-1.02 8.42-1.508 11.952.106 3.537 1.616 6.2 5.334 7.542 9.298z"
                fill="#00DFDD"
              />
              <path
                d="M35.487 14.262c3.965 2.968 8.639 4.96 10.868 8.583 2.238 3.614 2.04 8.869.385 13.496-1.648 4.62-4.745 8.611-8.621 10.75-3.877 2.13-8.523 2.388-12.847 1.927-4.324-.452-8.317-1.641-11.101-4.14-2.784-2.489-4.342-6.296-6.034-10.371-1.692-4.066-3.51-8.399-3.044-12.612.466-4.213 3.214-8.306 6.902-11.367 3.689-3.07 8.326-5.117 12.283-4.158 3.948.96 7.234 4.914 11.209 7.892z"
                fill="#00D197"
                sx={{ mixBlendMode: "lighten" }}
              />
              <path
                d="M21.362 49.311c-3.932 2.037-8.986-.039-10.606-3.892-1.62-3.854.213-9.493.454-14.675.24-5.182-1.102-9.911.018-14.194 1.125-4.296 4.709-8.155 8.897-9.144 4.189-.989 8.982.892 13.553 2.54 4.558 1.644 8.903 3.052 11.624 6.057 2.72 3.005 3.817 7.608 1.62 10.655-2.186 3.053-7.656 4.546-10.869 5.662-3.218 1.129-4.175 1.889-6.066 5.336-1.888 3.458-4.697 9.608-8.625 11.655z"
                fill="#FFC02A"
                sx={{ mixBlendMode: "lighten" }}
              />
              <path
                d="M35.162 38.97c.705 0 1.46-.21 2.267-.628.807-.418 1.609-.98 2.406-1.683l-.556-1.055-.39.17c-.741.381-1.196.571-1.362.571-.186 0-.279-.275-.279-.827 0-1.293.394-3.57 1.183-6.833.788-3.261 1.182-5.472 1.182-6.632 0-1.16-.297-2.064-.89-2.71-.594-.647-1.373-.97-2.337-.97-1.54 0-3.412.96-5.619 2.88-.148-.855-.482-1.55-1.001-2.082-.52-.532-1.16-.799-1.92-.799-1.687 0-3.597.98-5.73 2.939.074-.647.111-1.046.111-1.198 0-.704-.12-1.284-.361-1.74-2.541 0-4.33.161-5.37.484l-.083.428.279.4c.204.266.403.613.598 1.041.194.428.292.794.292 1.098-.223 2.073-.788 5.197-1.697 9.372s-1.4 6.69-1.474 7.546h.528c1.836 0 3.116-.19 3.839-.57 0-1.199.621-4.499 1.864-9.9.556-2.359.936-4.06 1.14-5.107 1.335-.989 2.504-1.483 3.505-1.483.686 0 1.03.504 1.03 1.512s-.446 3.47-1.336 7.389c-.872 3.841-1.307 6.561-1.307 8.159h.528c1.855 0 3.144-.19 3.867-.57-.019-.153-.028-.562-.028-1.228 0-.665.107-1.611.32-2.838.213-1.227.635-3.12 1.265-5.677.631-2.558 1.011-4.35 1.141-5.378 1.205-.913 2.309-1.37 3.31-1.37.723 0 1.085.476 1.085 1.427 0 .951-.385 3.148-1.154 6.59-.77 3.443-1.155 5.597-1.155 6.462 0 .865.214 1.55.64 2.054.427.504.983.756 1.67.756z"
                fill="#09063C"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default LogoIcon;

/*

import { jsx } from "theme-ui";

const LogoIcon = () => {
  return (
    <div sx={{ mt: 2, width: "100px", height: "100px" }}>
      <svg
        sx={{ width: "100%", height: "100%" }}
        viewBox="0 0 132 131"
        aria-hidden="true"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fillRule="nonzero" fill="none">
          <path
            d="M112.64 40.177c4.078 11.798 4.13 24.327 1.66 36.147-2.471 11.82-7.453 22.912-15.564 30.87-8.11 7.956-19.36 12.798-31.039 14.386-11.67 1.62-23.778.005-33.677-5.463-9.9-5.468-17.579-14.81-23.352-25.454-5.782-10.677-9.615-22.687-7.93-33.924 1.707-11.227 8.943-21.7 18.074-29.247 9.162-7.556 20.199-12.196 32.424-15.256 12.236-3.03 25.66-4.48 36.42.313 10.78 4.804 18.895 15.85 22.984 27.628z"
            fill="#00DFDD"
          />
          <path
            d="M92.842 24.156c12.086 8.82 26.326 14.737 33.119 25.502 6.82 10.738 6.22 26.351 1.173 40.102-5.02 13.724-14.459 25.585-26.271 31.94-11.813 6.328-25.971 7.095-39.148 5.725-13.176-1.342-25.343-4.876-33.827-12.3C19.404 107.73 14.657 96.418 9.5 84.31 4.345 72.23-1.193 59.355.225 46.836c1.419-12.518 9.794-24.68 21.034-33.774C32.499 3.94 46.629-2.141 58.687.708c12.031 2.848 22.043 14.6 34.155 23.448z"
            fill="#00D197"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            d="M49.8 128.297c-11.98 6.053-27.381-.115-32.317-11.566-4.936-11.45.649-28.204 1.382-43.602.733-15.397-3.357-29.449.054-42.175C22.35 18.19 33.27 6.724 46.032 3.786c12.763-2.939 27.37 2.65 41.298 7.549 13.89 4.882 27.13 9.066 35.421 17.995 8.292 8.929 11.634 22.604 4.937 31.658-6.659 9.07-23.329 13.508-33.118 16.824-9.807 3.353-12.723 5.61-18.486 15.855-5.752 10.273-14.313 28.548-26.283 34.63z"
            fill="#FFC02A"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            d="M30.223 97V66.112c0-4.473.845-8.214 2.536-11.222 1.69-3.008 3.843-5.225 6.456-6.652 2.613-1.427 5.303-2.14 8.07-2.14 4.073 0 7.359 1.215 9.857 3.644 2.498 2.43 3.747 6.15 3.747 11.164V97h9.222V65.302c0-4.01.788-7.462 2.364-10.354 1.575-2.892 3.65-5.09 6.225-6.594 2.575-1.504 5.4-2.256 8.474-2.256 4.073 0 7.359 1.215 9.856 3.644 2.498 2.43 3.747 6.15 3.747 11.164V97H110V59.98c0-4.858-1.038-8.946-3.113-12.262-2.075-3.317-4.784-5.765-8.127-7.346C95.416 38.79 91.823 38 87.98 38c-3.996 0-7.916.964-11.758 2.892-3.843 1.928-6.61 4.743-8.301 8.445-1.845-3.702-4.554-6.517-8.128-8.445C56.22 38.964 52.32 38 48.092 38c-3.766 0-7.282.848-10.549 2.545-3.266 1.697-5.898 4.242-7.897 7.635l-.576-9.023H21V97h9.223z"
            fill="#09063C"
          />
        </g>
      </svg>
    </div>
  );
};

export default LogoIcon;*/
