/** @jsx jsx */
import { jsx } from "theme-ui";

const IconLink = ({ children, label, ...props }) => (
  <a
    sx={{
      fontSize: [0, 0, 0, 1],
      my: [2, 1],
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "text",
      transition: "all .3s linear",
      willChange: "auto",
      textDecoration: "none",
      textAlign: "center",
      "&:hover": {
        color: "primary",
      },
      "&:focus": {
        color: "secondary",
        outline: "none",
      },
    }}
    {...props}
  >
    {children}
    {label && (
      <span
        sx={{
          display: ["none", "none", "inline-block"],
          ml: [0, 0, 1],
          textAlign: "center",
        }}
      >
        {label}
      </span>
    )}
  </a>
);

export default IconLink;

//variant: "text.visuallyHidden"
