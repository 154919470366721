/** @jsx jsx */
import { jsx } from "theme-ui";

const BasicButton = ({ children, type = "button", ...props }) => (
  <button type={type} sx={{ variant: "button.hollow" }} {...props}>
    {children}
  </button>
);

export default BasicButton;
