/** @jsx jsx */
import { jsx } from "theme-ui";
import { Fragment, useState, useEffect } from "react";
import { Transition } from "react-transition-group";

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const Fade = ({ in: inProp, children }) => (
  <Transition in={inProp} timeout={duration}>
    {(state) => (
      <div
        style={{
          ...defaultStyle,
          ...transitionStyles[state],
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);

const IntroHeading = ({
  name = "",
  introText = "",
  descText = "",
  statusText = "",
  ...props
}) => {
  //const isHome = location.pathname === "/";
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setInProp(true), 200);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Transition in={inProp} timeout={500}>
      {(state) => (
        <Fade in={inProp}>
          <Fragment>
            <h1
              sx={{
                width: "100%",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                pt: [1, 2],
              }}
            >
              <span sx={{ variant: "h1.primary" }}>{name}</span>
            </h1>
            <h2 sx={{ variant: "h2.primary", color: "accent" }}>{introText}</h2>
            <p
              sx={{
                variant: "p.intro",
                pb: [0],
              }}
            >
              {descText}
            </p>
            <p
              sx={{
                variant: "p.status",
                pb: [0, 2, 3],
              }}
            >
              {statusText}
            </p>
          </Fragment>
        </Fade>
      )}
    </Transition>
  );
};

export default IntroHeading;
