/** @jsx jsx */
import { jsx } from "theme-ui";

const CloseIcon = () => {
  return (
    <div sx={{ width: "22px", height: "22px" }}>
      <svg
        sx={{ width: "100%", height: "100%" }}
        viewBox="0 0 22 22"
        aria-hidden="true"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(-1164 -253)" fill="none" fillRule="evenodd">
          <path fill="none" d="M0 0h1440v1024H0z" />
          <path fill="none" opacity=".752" d="M0 0h1440v1024H0z" />
          <rect fill="none" x="244" y="224" width="974" height="509" rx="4" />
          <g fill="currentColor">
            <path d="M1164.759 273.265l19.506-19.506.975.975-19.506 19.506z" />
            <path d="M1165.734 253.76l19.507 19.506-.975.975-19.507-19.507z" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default CloseIcon;
