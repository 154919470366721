/** @jsx jsx */
import { jsx } from "theme-ui";

export const footerHeight = 60;

const PageFooter = ({ children }) => {
  return (
    <footer
      sx={{
        color: "text",
        fontSize: 0,
        borderTop: "1px solid rgba(255,255,255,0.4)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: ["auto", `${footerHeight}px`],
        mt: "auto",
        py: [1, 0],
        textAlign: "center",
      }}
    >
      {children}
    </footer>
  );
};
export default PageFooter;
