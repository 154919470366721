/** @jsx jsx */
import { jsx } from "theme-ui";

const GithubIcon = () => {
  return (
    <span
      sx={{
        width: "40px",
        height: "40px",
        color: "text",
        display: "inline-block",
        color: "inherit"
      }}
    >
      <svg
        sx={{ width: "100%", height: "100%" }}
        viewBox="0 0 50 50"
        aria-hidden="true"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <path fill="#09063C" d="M-1343-699H97V325h-1440z" />
          <g>
            <circle fill="currentColor" cx="25" cy="25" r="25" />
            <path
              d="M40 20.227l-.023-.108-.02-.063c-.013-.03-.023-.062-.038-.094-.004-.022-.019-.042-.025-.062l-.044-.084-.038-.062-.055-.075-.057-.057-.075-.056-.058-.037-.075-.055-.055-.05-.018-.025-13.694-9.162a1.28 1.28 0 00-1.428 0l-13.73 9.14-.02.02-.055.043-.075.062-.047.05-.063.07-.046.057-.062.075c-.025.021-.038.037-.038.057l-.062.075-.025.075c-.025.013-.025.05-.038.088l-.012.062c-.024.039-.024.076-.024.114v9.371c0 .055.004.113.012.169l.013.057c.006.038.012.075.025.108l.019.062c.012.034.02.067.033.094l.028.063c0 .012.019.05.037.075l.038.05c.019.012.037.05.056.075l.038.05.05.05c.012.016.012.037.037.037l.075.053.05.037.013.018 13.712 9.162c.205.15.469.204.713.204.243 0 .487-.075.712-.225l13.738-9.1.017-.013.058-.046.075-.054.06-.045.065-.072.041-.056.05-.075.037-.063.038-.087.02-.065.037-.097.02-.056.037-.1v-9.375c0-.062 0-.119-.02-.175l-.018-.056.055.004.029.023zm-14.988 7.85l-4.562-3.05 4.563-3.052 4.562 3.05-4.563 3.05v.003zm-1.292-8.342l-5.591 3.738-4.517-3.02 10.108-6.738v6.023-.003zm-7.913 5.291L12.58 27.19v-4.314l3.227 2.158v-.007zm2.322 1.563l5.591 3.737v6.025l-10.108-6.739 4.523-3.02v-.006l-.006.003zm8.171 3.737l5.592-3.725 4.517 3.025L26.3 36.364v-6.038zm7.912-5.287l3.23-2.15v4.32l-3.23-2.163v-.007zm-2.318-1.55l-5.592-3.739v-6.038L36.41 20.45l-4.515 3.019v.02z"
              fillRule="nonzero"
              fill="#000"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

export default GithubIcon;
