const GlobalStyles = ({ colors, lineHeights, fonts, easing }) => {
  return {
    html: { scrollBehavior: "smooth", height: "100%" },
    body: {
      color: colors.text,
      background: colors.background,
      fontFamily: fonts.body,
      margin: "0",
      padding: "0",
      textUnderlinePosition: "under",
      lineHeight: lineHeights.body,
      height: "100%",
      "&.dialog--open": {
        overflow: "hidden",
      },
    },
    "*": {
      boxSizing: "border-box",
    },
    "*::selection": {
      background: colors.muted,
      color: colors.primary,
    },
    "main * + *": {
      marginTop: 2,
    },
    h1: {
      margin: 0,
    },
    h2: {
      margin: 0,
    },
    h3: {
      margin: 0,
    },
    h4: {
      margin: 0,
    },
    img: {
      width: "100%",
      height: "auto",
    },
    ".dialog": {
      opacity: 0,
      transition: "opacity 0.2s linear",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      willChange: "transform, opacity",
      pointerEvents: "none",
    },
    ".dialog[aria-hidden='false']": {
      opacity: 1,
      pointerEvents: "auto",
    },
    ".fadeup-enter": {
      opacity: "0.01",
      transform: "translateY(20px)",
      transition: `opacity 300ms ${easing}, transform 300ms ${easing}`,
    },
    ".fadeup-enter-active": {
      opacity: "1",
      transform: "translateY(0px)",
      transition: `opacity 300ms ${easing}, transform 300ms ${easing}`,
    },
    ".fadedown-enter": {
      opacity: "0.01",
      transform: "translateY(-20px)",
      transition: `opacity 300ms ${easing}, transform 300ms ${easing}`,
    },
    ".fadedown-enter-active": {
      opacity: "1",
      transform: "translateY(0px)",
      transition: `opacity 300ms ${easing}, transform 300ms ${easing}`,
    },
    ".fade-enter": {
      opacity: "0.01",
      transition: `opacity 1000ms ${easing}`,
    },
    ".fade-enter-active": {
      opacity: "1",
      transition: `opacity 1000ms ${easing}`,
    },
    ".fadefromright-enter": {
      opacity: "0.01",
      transform: "translateX(70px)",
      transition: `opacity 300ms ${easing}, transform 300ms ${easing}`,
    },
    ".fadefromright-enter-active": {
      opacity: "1",
      transform: "translateX(0px)",
      transition: `opacity 300ms ${easing}, transform 300ms ${easing}`,
    },
  };
};

export default GlobalStyles;
