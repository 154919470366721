import { useEffect } from "react";
import ReactDOM from "react-dom";

const hasDocument = typeof document !== "undefined";
const appRoot = hasDocument && document.getElementById("___gatsby");
const containerEl = hasDocument && document.getElementById("dialog-root");
const dialogEl = hasDocument && document.createElement("div");

// Dialog component - manages dialog DOM node attributes
const Dialog = ({ isVisible, dialogLabelId, children }) => {
  useEffect(() => {
    if (hasDocument) {
      dialogEl.className = "dialog";
      dialogEl.setAttribute("role", "dialog");
      dialogLabelId && dialogEl.setAttribute("aria-labelledby", dialogLabelId);
      containerEl.appendChild(dialogEl);
    }
    return () => {
      containerEl.removeChild(dialogEl);
    };
  }, []);

  useEffect(() => {
    if (hasDocument) {
      // set dialog container attributes
      dialogEl.inert = !isVisible;
      dialogEl.setAttribute("aria-hidden", !isVisible);
      document.body.classList.toggle("dialog--open", isVisible);
      // set app container attributes
      appRoot.inert = isVisible;
      appRoot.setAttribute("aria-hidden", isVisible);
    }
  }, [isVisible]);

  return !hasDocument ? null : ReactDOM.createPortal(children, dialogEl);
};

export default Dialog;
