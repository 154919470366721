/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import IconLink from "../components/IconLink";
import GithubIcon from "../icons/GithubIcon";
import CodepenIcon from "../icons/CodepenIcon";
import MailIcon from "../icons/MailIcon";

const SideNav = ({ children }) => {
  const isHome = true;
  const [isMounted, setIsMounted] = useState(!isHome);

  useEffect(() => {
    if (!isHome) {
      return;
    }
    const timeout = setTimeout(() => setIsMounted(true), 2000);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <TransitionGroup component={null}>
      {isMounted && (
        <CSSTransition
          classNames={isHome ? "fadeup" : ""}
          timeout={isHome ? 2000 : 0}
        >
          <nav sx={{}}>
            <div
              sx={{
                maxWidth: ["40%", "40%", "90%", "80%"],
                margin: "0 auto",
                display: "flex",
                justifyContent: ["space-between", "space-between", "center"],
                "> *": {
                  my: [1, 2],
                  mr: [1, 3],
                },
              }}
            >
              <IconLink
                href="https://github.com/mariiinda"
                target="_blank"
                rel="noopener"
                label="Code on Github"
              >
                <GithubIcon />
              </IconLink>
              <IconLink
                href="https://codepen.io/mariiinda/"
                target="_blank"
                rel="noopener"
                label="Codepen Experiments"
              >
                <CodepenIcon />
              </IconLink>
              <IconLink
                href="mailto:hello@marinda.me?subject=availability"
                target="_blank"
                rel="noopener"
                label="Send me an email"
              >
                <MailIcon />
              </IconLink>
            </div>
          </nav>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};
export default SideNav;
