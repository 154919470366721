/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import { Global } from "@emotion/core";
import { MDXProvider } from "@mdx-js/react";

import LogoIcon from "../icons/LogoIcon";
import SectionNavigation from "./SectionNavigation";
import Header from "./Header";
import SideNav from "./SideNav";
import SEO from "./SEO";
import useSiteMetadata from "../hooks/use-site-meta-data";
import MarkdownComponents from "./MarkdownComponents";
import GlobalStyles from "../styles/GlobalStyles";
import PageFooter from "./PageFooter";

const PageLayout = ({ children }) => {
  const { title, image } = useSiteMetadata();

  return (
    <Styled.root>
      <SEO title={title} image={image} />
      <Global styles={GlobalStyles} />
      <div
        sx={(theme) => ({
          maxWidth: theme.breakpoints[theme.breakpoints.length - 1],
          mx: "auto",
          width: "90vw",
          fontSize: 1,
          height: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        })}
      >
        <Header>
          <LogoIcon />
          {/*<SectionNavigation /> */}
        </Header>
        <MDXProvider components={MarkdownComponents}>
          <main sx={{ variant: "layout.primary" }}>
            <div>{children}</div>
            <SideNav />
          </main>
        </MDXProvider>

        <PageFooter>
          <span>
            Built with 💛&nbsp;&nbsp;| Web tracking &amp; cookie free.
          </span>
        </PageFooter>
      </div>
    </Styled.root>
  );
};

export default PageLayout;
