import { graphql, useStaticQuery } from 'gatsby';

const useSections = () => {
  const data = useStaticQuery(graphql`
    query {
      allSectionsPage {
        nodes {
          id
          title
          path
          body
          updated
          order
        }
      }
    }
  `);

  return data.allSectionsPage.nodes;
};

export default useSections;
