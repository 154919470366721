/** @jsx jsx */
import { Flex, Box, jsx } from "theme-ui";

import IntroHeading from "./IntroHeading";
import Button from "./BasicButton";
import AvailabilityDialog from "./AvailabilityDialog";
import ProfileImage from "../components/ProfileImage";

// Components made available to markdown renderer
const MarkdownComponents = {
  Flex: Flex,
  Box: ({ sx, children }) => <Box sx={sx}>{children}</Box>,
  Button,
  AvailabilityDialog,
  IntroHeading,
  ProfileImage
};

export default MarkdownComponents;
