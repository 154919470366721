/** @jsx jsx */
import { jsx } from "theme-ui";

import useScroll from "../hooks/use-scroll";

export const headerHeight = 90;

const Header = ({ isHome, children }) => {
  const { y, direction } = useScroll();

  return (
    <header
      sx={(theme) => ({
        top: 0,
        left: 0,
        width: "100%",
        fontFamily: "heading",
        color: "text",
        bg: "background",
        position: "fixed",
        height: ["auto", `${headerHeight}px`],
        boxShadow: y < -120 ? "0 0 10px 2px rgba(0,0,0, .5);" : "none",
        transition: "transform .3s ease-in, box-shadow .3s linear",
        transform:
          direction === 1 ? `translate3d(0,0,0)` : `translate3d(0,-120px,0)`,
      })}
    >
      <div
        sx={(theme) => ({
          maxWidth: theme.breakpoints[theme.breakpoints.length - 1],
          mx: "auto",
          width: "90vw",
          height: "100%",
          margin: "0 auto",
          padding: 0,
          display: "flex",
          alignItems: "center",
        })}
      >
        {children}
      </div>
    </header>
  );
};
export default Header;
