/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect } from "react";

import Dialog from "./Dialog";
import CloseIcon from "../icons/CloseIcon";

const hasDocument = typeof document !== "undefined";
let initialFocusEl;

// DialogContainer component - manage state for opening and closing modal
const DialogContainer = ({
  children,
  isVisible,
  onClose,
  initialFocusSelector,
  onCloseFocusSelector,
  ...props
}) => {
  useEffect(() => {
    const onKeydown = e => {
      if (e.keyCode == 27) {
        onClose();
      }
    };
    if (hasDocument) {
      document.body.addEventListener("keydown", onKeydown);
      return () => {
        document.body.removeEventListener("keydown", onKeydown);
      };
    }
  }, []);

  useEffect(() => {
    if (hasDocument) {
      if (isVisible) {
        initialFocusEl = document.querySelector(initialFocusSelector);
        initialFocusEl && initialFocusEl.focus();
      }
    }
  }, [isVisible]);
  return (
    <Dialog isVisible={isVisible} {...props}>
      <div
        sx={{
          position: "absolute",
          top: "10%",
          left: "50%",
          transform: "translate3d(-50%, -150%, 0) scale(0.0001)",
          willChange: "transform",
          width: "70%",
          boxShadow: `0 24px 38px 3px rgba(0, 0, 0, 0.14),0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2)`,
          maxWidth: "200ch",
          minWidth: "320px",
          maxHeight: ["98vh", "98vh", "70vh"],
          overflowY: "auto",
          padding: 1,
          bg: "background",
          "[aria-hidden='false'] &": {
            transform: "translate3d(-50%, -10%, 0) scale(1)",
            transition: "transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1)"
          },
          "[aria-hidden='true'] &": {
            transition: "transform .25s ease-in"
          }
        }}
      >
        <button
          type="button"
          id="dialog-close-btn"
          sx={{
            variant: "button.small",
            color: "text",
            position: "absolute",
            top: [0, 1],
            right: [0, 1]
          }}
          onClick={onClose}
        >
          <CloseIcon />
          <span sx={{ variant: "text.visuallyHidden" }}>Close</span>
        </button>
        <div>{children}</div>
      </div>
    </Dialog>
  );
};

export default DialogContainer;
