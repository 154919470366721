/** @jsx jsx */
import { jsx } from "theme-ui";

const AvailabilityGraph = ({ data, ...props }) => {
  const getColor = (status) => {
    if (status === 0) return "background";
    if (status === 1) return "secondary";
    return "highlight";
  };

  return (
    <div>
      <div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          p: 2,
        }}
        {...props}
      >
        {data.map(({ id, label, status }) => (
          <div
            key={id}
            sx={{
              textTransform: "uppercase",
              width: "90px",
              height: "90px",
              borderRadius: "6px",
              backgroundColor: getColor(status),
              flex: "0 0 90px",
              fontSize: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: status === 0 ? "text" : "background",
              fontWeight: "bold",
              border: "2px solid transparent",
              borderColor: status === 0 ? "text" : "background",
              opacity: status === 0 ? 0.3 : 1,
              boxShadow: `0 24px 38px 3px rgba(0, 0, 0, 0.14),0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2)`,
              transform: "rotate(45deg)",
            }}
            aria-label={label}
          >
            <span
              sx={{
                display: "block",
                textTransform: "uppercase",
                transform: "rotate(-45deg)",
                textDecoration: status === 0 ? "line-through" : "none",
                lineHeight: 0,
              }}
            >
              {id}
            </span>
          </div>
        ))}
      </div>

      {[1, 2].map((status) => (
        <div key={status} sx={{ m: [0, 1], display: "inline-block" }}>
          <span
            sx={{
              display: "inline-block",
              width: "12px",
              height: "12px",
              borderRadius: "6px",
              backgroundColor: getColor(status),
              mr: 1,
            }}
          />
          {status === 1 ? "Available" : "Partially available"}
        </div>
      ))}
    </div>
  );
};

export default AvailabilityGraph;
