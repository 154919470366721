import { useState, useEffect } from "react";

const useScroll = () => {
  const [scroll, setScroll] = useState({
    x: typeof window !== "undefined" ? window.scrollX : 0,
    y: typeof window !== "undefined" ? window.scrollY : 0,
    direction: 1
  });

  const listener = e => {
    // `prev` provides us the previous state: https://reactjs.org/docs/hooks-reference.html#functional-updates
    setScroll(prev => ({
      x: window.scrollX,
      y: -window.scrollY,
      // Here we’re comparing the previous state to the current state to get the scroll direction
      direction: prev.y > -window.scrollY ? -1 : 1
    }));
  };

  useEffect(() => {
    window.addEventListener("scroll", listener);
    // cleanup function occurs on unmount
    return () => window.removeEventListener("scroll", listener);
    // Run `useEffect` only once on mount, so add `, []` after the closing curly brace }
  }, []);

  return scroll;
};

export default useScroll;
