AvailabilityGraph; /** @jsx jsx */
import { jsx } from "theme-ui";
import { Fragment, useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import DialogContainer from "./DialogContainer";
import AvailabilityGraph from "./AvailabilityGraph";

const hasDocument = typeof document !== "undefined";
let prevFocus;

const AvailabilityDialog = ({ children, type = "button", ...props }) => {
  const [isVisible, setIsVisible] = useState(false);
  const isHome = true;
  const [isMounted, setIsMounted] = useState(!isHome);

  useEffect(() => {
    if (!isHome) {
      return;
    }
    const timeout = setTimeout(() => setIsMounted(true), 800);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (hasDocument && !isVisible) {
      prevFocus && prevFocus.focus();
      prevFocus = null;
    }
  }, [isVisible]);

  return (
    <Fragment>
      <TransitionGroup component={null}>
        {isMounted && (
          <CSSTransition
            classNames={isHome ? "fadeup" : ""}
            timeout={isHome ? 800 : 0}
          >
            <button
              onClick={() => {
                prevFocus = document.activeElement;
                setIsVisible(true);
              }}
              type={type}
              sx={{ variant: "button.hollow", mb: [1, 2], fontSize: [1, 2] }}
              {...props}
            >
              {children}
            </button>
          </CSSTransition>
        )}
      </TransitionGroup>
      <DialogContainer
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
        dialogLabelId="availability"
        initialFocusSelector="#dialog-content"
      >
        <div
          tabIndex="0"
          id="dialog-content"
          sx={(theme) => {
            return {
              p: [2, 3],
              outline: "none",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              "&:focus": {
                outline: `2px dotted ${theme.colors.primary}`,
              },
            };
          }}
        >
          <h1 id="availability" sx={{ variant: "h1.large" }}>
            Availability
          </h1>
          <AvailabilityGraph
            data={[
              { id: "dec '22", label: "December '21", status: 0 },
              { id: "jan '23", label: "Jan '23", status: 2 },
              { id: "feb '23", label: "Feb '23", status: 2 },
              { id: "mar '23", label: "Mar '23", status: 0 },
              { id: "apr '23", label: "April '23", status: 0 },
              { id: "may '23", label: "May '23", status: 0 },
              { id: "jun '23", label: "June '23", status: 0 },
              { id: "jul '23", label: "July '23", status: 0 },
              { id: "aug '23", label: "August '23", status: 0 },
              { id: "sep '23", label: "September '23", status: 1 },
              { id: "oct '23", label: "October '23", status: 1 },
              { id: "nov '23", label: "November '23", status: 1 },
            ]}
          />
          <p
            sx={{
              variant: "text.small",
              maxWidth: "50ch",
            }}
          >
            Preferred rates, contract duration and working location depends on
            the project. I usually work remotely with limited on-site and take
            on 3-6 month contracts.
          </p>
          <p
            sx={{
              variant: "text.medium",
            }}
          >
            Interested in booking me for a project?
            <br />
            <a
              id="email-link"
              sx={{
                variant: "link.primary",
              }}
              href="mailto:hello@marinda.me?subject=availability"
            >
              Get in touch.
            </a>
          </p>
        </div>
      </DialogContainer>
    </Fragment>
  );
};

export default AvailabilityDialog;
