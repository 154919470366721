/** @jsx jsx */
import { jsx } from "theme-ui";

const GithubIcon = () => {
  return (
    <span
      sx={{
        width: "40px",
        height: "40px",
        color: "text",
        display: "inline-block",
        color: "inherit"
      }}
    >
      <svg
        sx={{ width: "100%", height: "100%" }}
        viewBox="0 0 50 50"
        aria-hidden="true"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <path fill="#09063C" d="M-1343-768H97V256h-1440z" />
          <g>
            <circle fill="currentColor" cx="25" cy="25" r="25" />
            <path
              d="M12.037 15c-.238 0-.462.041-.674.125l4.245 4.371 4.293 4.45.078.093.125.125.126.126.25.266 3.682 3.776c.061.038.239.202.378.272.178.089.372.171.572.178.215.008.436-.054.63-.148.145-.07.21-.172.378-.302l4.261-4.402 4.308-4.434 4.152-4.277a1.822 1.822 0 00-.878-.219H12.037zm-1.3.533c-.453.429-.737 1.074-.737 1.801v14.35c0 .59.19 1.125.501 1.535l.596-.564 4.433-4.308 3.932-3.807-.078-.094-4.308-4.433-4.308-4.449-.032-.031zm28.668.14l-4.199 4.34-4.292 4.433-.078.079 4.088 3.963 4.434 4.308.266.251c.239-.383.376-.855.376-1.363v-14.35c0-.649-.225-1.237-.595-1.66zm-19.442 9.384l-3.916 3.807-4.45 4.308-.563.549c.297.191.636.313 1.003.313h25.926c.442 0 .842-.17 1.175-.439l-.282-.282-4.449-4.308-4.088-3.948-3.682 3.792c-.2.132-.332.278-.527.368-.313.145-.656.267-1.001.262-.346-.005-.685-.14-.995-.293-.156-.077-.24-.153-.422-.306l-3.729-3.823z"
              fill="#09063C"
              fillRule="nonzero"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

export default GithubIcon;
