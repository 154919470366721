/** @jsx jsx */
import { jsx } from "theme-ui";

const GithubIcon = () => {
  return (
    <span
      sx={{
        width: "40px",
        height: "40px",
        color: "text",
        display: "inline-block",
        color: "inherit"
      }}
    >
      <svg
        sx={{ width: "100%", height: "100%" }}
        viewBox="0 0 50 50"
        aria-hidden="true"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <path fill="none" d="M-1343-630H97V394h-1440z" />
          <path
            d="M25 .619c-13.813 0-25 11.194-25 25 0 11.048 7.162 20.416 17.094 23.718 1.25.236 1.708-.537 1.708-1.202 0-.593-.02-2.166-.031-4.25-6.954 1.509-8.421-3.354-8.421-3.354-1.137-2.885-2.781-3.656-2.781-3.656-2.265-1.55.175-1.519.175-1.519 2.51.175 3.829 2.575 3.829 2.575 2.23 3.823 5.852 2.719 7.281 2.08.225-1.617.869-2.72 1.584-3.344-5.553-.625-11.388-2.775-11.388-12.355 0-2.729.969-4.958 2.573-6.708-.281-.631-1.125-3.173.219-6.616 0 0 2.093-.671 6.875 2.562 2-.556 4.125-.831 6.25-.844 2.125.013 4.25.288 6.25.844 4.75-3.233 6.843-2.563 6.843-2.563 1.344 3.444.5 5.986.25 6.617 1.594 1.75 2.563 3.98 2.563 6.709 0 9.604-5.844 11.718-11.406 12.333.875.75 1.687 2.283 1.687 4.625 0 3.346-.031 6.033-.031 6.846 0 .656.437 1.437 1.719 1.187C42.844 46.025 50 36.65 50 25.62c0-13.806-11.194-25-25-25"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      </svg>
    </span>
  );
};

export default GithubIcon;
